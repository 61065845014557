* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

:root {
    --bg: #fff;
    --green: #D1E1D2;
    --darkgreen: #96D0A6;
    --footergreen: #005A51;
    --orange: #FFBF23;
    --gray: #6E6E6E;
    --black: #1D1D1F
}

body {
    background-color: #fff;
    color: #1d1d1f;
    font-family: Inter;
    text-align: justify;
}

h1 {
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Arial';
    line-height: 55px
}

h2 {
    font-style: normal;
    font-weight: 500;
    font-family: 'Arial';
    line-height: 55px;
    color: #1d1d1f
}

h3 {
    color: #f5f5f7;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 52px;
    letter-spacing: -2.44px
}

h4 {
    color: #7d8590;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px
}

/* .content-small-p p{margin:0;color:var(--grey);font-size:18px;font-style:normal;font-weight:400;line-height:24px}
.content-box p{margin:0;color:var(--grey);font-size:24px;font-style:normal;font-weight:400;line-height:28px}
header{background:rgba(255,255,255,1)}
a.nav-link{color:var(--grey);font-size:14px;font-style:normal;font-weight:400;padding:0 20px}
a.nav-link:hover{color:#000}
.login-but{background:#000;padding:10px 20px 10px 20px!important;border-radius:5px;color:#fff!important;margin-left:15px;margin-top:-12px}
.get-but{background:#000;padding:10px 20px 10px 20px!important;border-radius:5px;color:#fff!important;text-decoration:none}
.topbanner-section{padding:100px 0}
.fl-right{float:right}
.topbanner-section p{color:var(--black);font-size:20px;font-style:normal;font-weight:400;line-height:28px}
.m-b-55{margin-bottom:55px}
.m-t-40{margin-top:40px}
.m-b-40{margin-bottom:40px}
.m-t-30{margin-top:30px}
.m-b-30{margin-bottom:30px}
.m-t-60{margin-top:60px}
.m-r-10{margin-right:10px}
.m-t-20{margin-top:20px}
.p-t-30{margin-top:30px}
.speed{margin:0;padding:0}
.speed li{font-size:13px;list-style-type:none;width:170px;float:left;line-height:16px; margin-bottom: 20PX;}
.spn li{ width: 46%;} */
/* .speed li span{position:absolute} */
.speed1 {
    margin-left: 14px !important
}

.speed1 li span {
    position: relative !important;
    left: -14px;
    line-height: 35px;
}

.greenshape-bg {
    background-image: url(../images/green-top-bg.webp);
    background-size: cover;
    height: 167px
}

.greenshape {
    background: #d1e1d2;
    padding-bottom: 80px
}

.green-head {
    font-size: 38px;
    line-height: 55px;
    font-family: 'Arial'
}

.greenshape p {
    font-size: 18px;
    line-height: 28px;
    text-align: justify;
}

.service-sec {
    /* padding: 100px 0 */
    padding: 30px 0
}

.service-img {
    margin: 30px 0 50px 0
}

.service-img img {
    width: 100%
}

.service-sec h3 {
    font-size: 24px;
    line-height: 30px;
    font-style: normal;
    font-weight: 700;
    font-family: 'Arial';
    color: #000;
    letter-spacing: 1px
}

.service-sec p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400
}

.mtxt {
    min-height: auto !important
}

.built {
    max-width: 100%
}

.service-project {
    padding: 70px 0
}

.service-project p {
    padding-bottom: 20px
}

.speed li img {
    z-index: 99999;
    position: relative;
    float: left;
    max-width: 100%;
    top: 0
}

.learnmore {
    color: #00bc1e;
    font-size: 14px
}

.learnmore:hover {
    color: #000;
    font-size: 14px
}

.gree-box {
    width: 600px;
    height: 500px;
    border-radius: 24px 0 0 24px;
    background: #d1e1d2;
    float: right;
    position: absolute;
    right: 0
}

.service-project h3 {
    font-size: 35px;
    line-height: 55px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Arial';
    color: #1d1d1f
}

.service-project img {
    z-index: 99999;
    position: relative;
    float: right;
    max-width: 100%;
    top: -20px
}

.service-project1 {
    padding: 200px 0 115px 0
}

.service-project1 h3 {
    font-size: 35px;
    line-height: 55px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Arial';
    color: #1d1d1f
}

.service-project1 img {
    z-index: 99999;
    position: relative;
    float: left;
    top: 40px;
    left: -15px;
    max-width: 100%
}

.pur-box {
    width: 600px;
    height: 500px;
    border-radius: 0 24px 24px 0;
    background: #ffd3f4;
    position: absolute;
    LEFT: 0
}

.pd-t-80 {
    padding-top: 80px
}

.grayshape-bg {
    background-image: url(../images/gray-top-bg.webp);
    background-size: cover;
    height: 167px
}

.grayshape {
    background: #f5f5f5;
    padding-bottom: 50px
}

/* .slick-slidez{margin:0 50px!important}
.slick-slidez1{margin:0 0px 0 50px!important}
.slick-slidez1 img{width: 80%!important; } */

.testimonial-bg {
    background-image: url(../images/Testimonials-bg.webp);
    background-repeat: no-repeat;
    height: 400px;
    padding: 70px 20px 20px 20px;
    width: 290px;
    margin: 20px auto
}

.testimonial-bg p {
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    min-height: 150px;
}

.testimonial-plant {
    position: relative;
    bottom: -50px;
    left: -35px
}

.test-head {
    position: relative;
    left: 100px;
    top: -60px;
    font-weight: 600;
    font-size: 13px;
}

.contact-bg {
    background: #80a589;
    padding: 50px 0;
    color: #fff
}

.contact-img {
    position: relative;
    left: 0;
    bottom: -90px;
    max-width: 100%
}

.contact-bg h2 {
    font-size: 38px;
    line-height: 50px;
    font-weight: 400;
    font-family: 'Arial';
    color: #fff;
    margin-bottom: 50px
}

.contact-pad {
    padding: 0 0 0 50px
}

.form-control,
.form-group .form-control {
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688)), -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
    background-image: -webkit-linear-gradient(#009688, #009688), -webkit-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: -o-linear-gradient(#009688, #009688), -o-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: linear-gradient(#009688, #009688), linear-gradient(#d2d2d2, #d2d2d2);
    -webkit-background-size: 0 2px, 100% 1px;
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center -webkit-calc(100% - 1px);
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0
}

.read-more {
    background: #000;
    padding: 10px 20px 10px 20px !important;
    border-radius: 5px;
    color: #fff !important;
    margin-left: 0;
    width: 150px;
    text-decoration: none
}

.form-control::-moz-placeholder,
.form-group .form-control::-moz-placeholder {
    color: #bdbdbd;
    font-weight: 400
}

.form-control:-ms-input-placeholder,
.form-group .form-control:-ms-input-placeholder {
    color: #bdbdbd;
    font-weight: 400
}

.form-control::-webkit-input-placeholder,
.form-group .form-control::-webkit-input-placeholder {
    color: #bdbdbd;
    font-weight: 400
}

.form-control[disabled],
.form-control[readonly],
.form-group .form-control[disabled],
.form-group .form-control[readonly],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
    background-color: rgba(0, 0, 0, 0)
}

.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
    background-image: none;
    border-bottom: 1px dotted #d2d2d2
}

.form-group {
    position: relative
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-static label.control-label {
    position: absolute;
    pointer-events: none;
    -webkit-transition: .3s ease all;
    -o-transition: .3s ease all;
    transition: .3s ease all
}

.form-group.label-floating label.control-label {
    will-change: left, top, contents
}

.form-group.label-placeholder:not(.is-empty) label.control-label {
    display: none
}

.form-group .help-block {
    position: absolute;
    display: none
}

.form-group.is-focused .form-control {
    outline: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688)), -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
    background-image: -webkit-linear-gradient(#009688, #009688), -webkit-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: -o-linear-gradient(#009688, #009688), -o-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: linear-gradient(#009688, #009688), linear-gradient(#d2d2d2, #d2d2d2);
    -webkit-background-size: 100% 2px, 100% 1px;
    background-size: 100% 2px, 100% 1px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s
}

.form-group.is-focused .form-control .material-input:after {
    background-color: #009688
}

.form-group.is-focused label,
.form-group.is-focused label.control-label {
    color: #009688
}

.form-group.is-focused.label-placeholder label,
.form-group.is-focused.label-placeholder label.control-label {
    color: #bdbdbd
}

.form-group.is-focused .help-block {
    display: block
}

.form-group.has-warning .form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-warning.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff5722), to(#ff5722)), -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
    background-image: -webkit-linear-gradient(#ff5722, #ff5722), -webkit-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: -o-linear-gradient(#ff5722, #ff5722), -o-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#d2d2d2, #d2d2d2)
}

.form-group.has-warning .help-block,
.form-group.has-warning label.control-label {
    color: #ff5722
}

.form-group.has-error .form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-error .help-block,
.form-group.has-error label.control-label {
    color: #f44336
}

.form-group.has-success .form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-success.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4caf50), to(#4caf50)), -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
    background-image: -webkit-linear-gradient(#4caf50, #4caf50), -webkit-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: -o-linear-gradient(#4caf50, #4caf50), -o-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#d2d2d2, #d2d2d2)
}

.form-group.has-success .help-block,
.form-group.has-success label.control-label {
    color: #4caf50
}

.form-group.has-info .form-control {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-info.is-focused .form-control {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#03a9f4), to(#03a9f4)), -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
    background-image: -webkit-linear-gradient(#03a9f4, #03a9f4), -webkit-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: -o-linear-gradient(#03a9f4, #03a9f4), -o-linear-gradient(#d2d2d2, #d2d2d2);
    background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#d2d2d2, #d2d2d2)
}

.form-group.has-info .help-block,
.form-group.has-info label.control-label {
    color: #03a9f4
}

.form-group textarea {
    resize: none
}

.form-group textarea~.form-control-highlight {
    margin-top: -11px
}

.form-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-group select~.material-input:after {
    display: none
}

.form-control {
    margin-bottom: 7px
}

.form-control::-moz-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #bdbdbd;
    font-weight: 400
}

.form-control:-ms-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #bdbdbd;
    font-weight: 400
}

.form-control::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #bdbdbd;
    font-weight: 400
}

.checkbox label,
.radio label,
label {
    font-size: 16px;
    line-height: 1.42857143;
    color: #bdbdbd;
    font-weight: 400
}

label.control-label {
    font-size: 12px;
    line-height: 1.07142857;
    font-weight: 400;
    margin: 16px 0 0 0
}

.help-block {
    margin-top: 0;
    font-size: 12px
}

.form-group {
    padding-bottom: 7px;
    margin: 28px 0 0 0
}

.form-group .form-control {
    margin-bottom: 7px
}

.form-group .form-control::-moz-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #bdbdbd;
    font-weight: 400
}

.form-group .form-control:-ms-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #bdbdbd;
    font-weight: 400
}

.form-group .form-control::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 1.42857143;
    color: #bdbdbd;
    font-weight: 400
}

.form-group .checkbox label,
.form-group .radio label,
.form-group label {
    font-size: 16px;
    line-height: 1.42857143;
    color: #bdbdbd;
    font-weight: 400
}

.form-group label.control-label {
    font-size: 12px;
    line-height: 1.07142857;
    font-weight: 400;
    margin: 16px 0 0 0
}

.form-group .help-block {
    margin-top: 0;
    font-size: 12px
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
    top: -7px;
    font-size: 16px;
    line-height: 1.42857143
}

.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label,
.form-group.label-static label.control-label {
    top: -30px;
    left: 0;
    font-size: 12px;
    line-height: 1.07142857
}

.form-group.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -30px;
    left: 0;
    font-size: 12px;
    line-height: 1.07142857
}

.form-group.form-group-sm {
    padding-bottom: 3px;
    margin: 21px 0 0 0
}

.form-group.form-group-sm .form-control {
    margin-bottom: 3px
}

.form-group.form-group-sm .form-control::-moz-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #bdbdbd;
    font-weight: 400
}

.form-group.form-group-sm .form-control:-ms-input-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #bdbdbd;
    font-weight: 400
}

.form-group.form-group-sm .form-control::-webkit-input-placeholder {
    font-size: 11px;
    line-height: 1.5;
    color: #bdbdbd;
    font-weight: 400
}

.form-group.form-group-sm .checkbox label,
.form-group.form-group-sm .radio label,
.form-group.form-group-sm label {
    font-size: 11px;
    line-height: 1.5;
    color: #bdbdbd;
    font-weight: 400
}

.form-group.form-group-sm label.control-label {
    font-size: 9px;
    line-height: 1.125;
    font-weight: 400;
    margin: 16px 0 0 0
}

.form-group.form-group-sm .help-block {
    margin-top: 0;
    font-size: 9px
}

.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
    top: -11px;
    font-size: 11px;
    line-height: 1.5
}

.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-sm.label-static label.control-label {
    top: -25px;
    left: 0;
    font-size: 9px;
    line-height: 1.125
}

.form-group.form-group-sm.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -25px;
    left: 0;
    font-size: 9px;
    line-height: 1.125
}

.form-group.form-group-lg {
    padding-bottom: 9px;
    margin: 30px 0 0 0
}

.form-group.form-group-lg .form-control {
    margin-bottom: 9px
}

.form-group.form-group-lg .form-control::-moz-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #bdbdbd;
    font-weight: 400
}

.form-group.form-group-lg .form-control:-ms-input-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #bdbdbd;
    font-weight: 400
}

.form-group.form-group-lg .form-control::-webkit-input-placeholder {
    font-size: 18px;
    line-height: 1.3333333;
    color: #bdbdbd;
    font-weight: 400
}

.form-group.form-group-lg .checkbox label,
.form-group.form-group-lg .radio label,
.form-group.form-group-lg label {
    font-size: 18px;
    line-height: 1.3333333;
    color: #bdbdbd;
    font-weight: 400
}

.form-group.form-group-lg label.control-label {
    font-size: 14px;
    line-height: .99999998;
    font-weight: 400;
    margin: 16px 0 0 0
}

.form-group.form-group-lg .help-block {
    margin-top: 0;
    font-size: 14px
}

.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
    top: -5px;
    font-size: 18px;
    line-height: 1.3333333
}

.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label,
.form-group.form-group-lg.label-static label.control-label {
    top: -32px;
    left: 0;
    font-size: 14px;
    line-height: .99999998
}

.form-group.form-group-lg.label-floating input.form-control:-webkit-autofill~label.control-label label.control-label {
    top: -32px;
    left: 0;
    font-size: 14px;
    line-height: .99999998
}

select.form-control {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0
}

.form-group.is-focused select.form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #d2d2d2
}

.form-group.is-focused select.form-control[multiple],
select.form-control[multiple] {
    height: 85px
}

.input-group-btn .btn {
    margin: 0 0 7px 0
}

.form-group.form-group-sm .input-group-btn .btn {
    margin: 0 0 3px 0
}

.form-group.form-group-lg .input-group-btn .btn {
    margin: 0 0 9px 0
}

.input-group .input-group-btn {
    padding: 0 12px
}

.input-group .input-group-addon {
    border: 0;
    background: 0 0
}

.form-group input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100
}

.contact-widget-section .single-contact-widget {
    background: #f9f9f9;
    padding: 20px 25px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .26);
    height: 260px;
    margin-top: 25px;
    transition: all .3s ease-in-out
}

.contact-widget-section .single-contact-widget i {
    font-size: 75px
}

.contact-widget-section .single-contact-widget h3 {
    font-size: 20px;
    color: #333;
    font-weight: 700;
    padding-bottom: 10px
}

.contact-widget-section .single-contact-widget p {
    line-height: 16px
}

.contact-widget-section .single-contact-widget:hover {
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .46);
    cursor: pointer;
    transition: all .3s ease-in-out
}

#contactform {
    margin-top: -10px
}

#contactform .form-group label.control-label {
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

#contactform .form-control {
    font-weight: 500;
    height: auto;
    color: var(--bg)
}

span#submit {
    border-radius: 5px;
    color: #fff;
    background: #000;
    padding: 10px
}

.book-a-demo {
    background: #000;
    padding: 10px 20px 10px 20px !important;
    border-radius: 5px;
    color: #fff !important;
    margin-left: 0;
    position: relative;
    left: 0;
    top: 15px;
    width: 150px;
    text-decoration: none
}

.right-txt {
    text-align: right
}

.icon-font {
    font-size: 30px;
    color: #fff;
    padding-right: 30px
}

.fot-head {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding-bottom: 50px;
    padding-top: 50px
}

.foot p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    padding-bottom: 30px;
    color: var(--bg);
    opacity: .6
}

.footer-link {
    margin: 0;
    padding: 0
}

.footer-link li {
    list-style-type: none;
    color: #b6d5d1;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px
}

/* .footer-link li:hover{list-style-type:none;color:var(--bg);opacity:1} */
.fo-link {
    list-style-type: none;
    color: #b6d5d1;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px;
    text-decoration: none;
}

.fo-link:hover {
    list-style-type: none;
    color: #fff
}

.footer-bg {
    background: #005a51;
    padding: 70px 0
}

.copyright-bg {
    background: #003c36;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: var(--bg)
}

.bok-dis {
    display: inline-block
}

.line-hei {
    line-height: 36px;
}

#navbarz {
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    transition: top 0.3s;
}

@media only screen and (max-width:2500px) and (min-width:1800px) {
    .container {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 300px);
        max-width: 100%
    }
}

@media screen and (max-width:900px) {
    .gree-box {
        display: none
    }

    .pur-box {
        display: none
    }

    .service-project img {
        top: 50px
    }

    .service-project1 {
        padding: 40px 0 115px 0
    }

    .contact-img {
        position: inherit
    }

    .contact-pad {
        padding: 25px 0 0 15px
    }

    .fot-head {
        padding-bottom: 20px;
        padding-top: 20px
    }

    .c-txt {
        text-align: center !important
    }

    .m-b-30z {
        margin-bottom: 30px
    }

    .service-img img {
        max-width: 100%
    }

    .service-project1 img {
        left: 0
    }

    .speed li span {
        position: relative
    }

    /* .speed li:last-child{margin-top:20px} */
    .service-sec {
        padding: 50px 0 0 0
    }

    .pd-t-80 {
        padding-top: 0px
    }

    .speed1 li span {
        position: relative !important;
        left: 0
    }

    .speed1 {
        margin-left: 0 !important
    }

    .pdm-t-10 {
        padding-top: 10px
    }

    li.nav-item {
        line-height: 45px;
        border-bottom: 1px solid #999
    }

    li.nav-item:last-child {
        border: none;
        margin-top: 45px
    }

    #contactform {
        margin-top: -10px;
        width: 96%
    }

    .dessiplay {
        display: none;
    }

    .mobsiplay {
        display: block !important;
    }

    .speed li {
        width: 100%;
    }

    .greenshape-bg {
        height: 70px;
    }

    .testimonial-bg {
        margin-bottom: 30px;
    }

    .pad-10 {
        padding-top: 10px;
    }

    .service-project img {
        position: inherit;
        float: none;
        max-width: 100%;
        top: 0px;
    }

    .service-project1 img {
        position: inherit;
        float: none;
        top: 0px;
        left: -15px;
        max-width: 100%;
    }

    .indus-link {
        display: none;
    }

    .footer-link li {
        display: inline-block;
        padding-right: 40px;
    }
}

.grayshape {
    margin-bottom: 100px;
}

.mobsiplay {
    display: none;
}

@media (min-width: 768px) {
    .container-md {
        max-width: 70%;
        margin: 0;
    }
}

@media only screen and (max-width:1620px) and (min-width:1360px) {
    .speed li {
        margin-bottom: 30px;
    }

    .margin-top {
        margin-top: 120px;
    }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .mt-sm-10 {
        margin-top: 30px !important;
        margin-bottom: -5px;
    }
}

.slider {
    padding: 0.5rem;
}

.slide>img {
    width: 150px;
    height: 50px;
}

#contactform .form-control {
    font-weight: 500;
    height: 55px;
    color: var(--bg);
}

.form-group .form-control {
    margin-bottom: 7px;
}

.form-control,
.form-group .form-control {
    border: 0;
    background-image: linear-gradient(#009688, #009688), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
}

.form-control {
    margin-bottom: 7px;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact-button {
    padding: 10px 12px;
    border-radius: 5px;
    background-color: black;
    color: white;
    border: none;
}

#contactform .form-control:focus {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.margin-bottom {
    margin-bottom: 80px;
}

@media (max-width: 575px) {
    #res-col {
        width: 90%;
        text-align: justify;
        margin: auto;
    }

    #reduce-size {
        width: 90%;
        margin: auto;
    }

    #reduce-size2 {
        width: 90%;
        margin: auto auto auto 32px;
    }

    .slider {
        /*padding: 3rem 0.5rem;*/
        /*padding: 3rem 0.5rem 3rem 1rem;*/
        padding: 0rem;
    }

    .ml-15 {
        margin-left: 15px;
        font-size: 28px;
    }

    .service-sec {
        padding: 0px 0 0 0
    }
}

@media only screen and (max-width:580px) and (min-width:280px) {
    .margin-top {
        margin: 0px auto;
    }

    .mt-sm-10 {
        margin-top: 30px !important;
        margin-bottom: -5px;
    }

    .grayshape {
        margin-bottom: 80px;
    }

    .pt-sm {
        padding-top: 40px;
    }

    .green-head {
        font-size: 30px;
        line-height: 40px;
    }

    /* .slide > img {width: 170px;height: 70px;} */
    .slide>img {
        width: 40%;
        aspect-ratio: 5/3;
        object-fit: contain;
        mix-blend-mode: color-burn;
    }
}